import { ColorType } from 'theme'
import React from 'react'
import styled from 'styled-components'

export type TagColors = 'orange' | 'green' | 'purple' | 'white' | 'teal' | 'charcoal'

export type TagSize = 'large' | 'small'

type Props = {
  color: TagColors
  size?: TagSize
  children: string
}

const Tag = ({ color, size = 'large', children }: Props) => (
  <Container $color={color} $size={size}>
    {children}
  </Container>
)

export default Tag

type TagSchemeType = {
  [T in TagColors]: {
    background: ColorType

    color: ColorType
  }
}

const TAG_COLORS: TagSchemeType = {
  orange: {
    background: 'orange20',
    color: 'orangeText',
  },
  green: {
    background: 'green20',
    color: 'greenText',
  },
  purple: {
    background: 'purple20',
    color: 'purpleText',
  },
  white: {
    background: 'white10',
    color: 'white',
  },
  teal: {
    background: 'teal10',
    color: 'tealText',
  },
  charcoal: {
    background: 'charcoal5',
    color: 'charcoal80',
  },
}

type StyledContainerProps = {
  $color: TagColors
  $size: TagSize
}

const Container = styled.div<StyledContainerProps>`
  width: fit-content;
  height: ${({ $size }) => ($size === 'small' ? '20px' : '24px')};
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 4px;
  ${({ theme, $size }) => theme.typography[$size === 'small' ? 'h5' : 'body-small-400'].mobile}

  @media ${({ theme }) => theme.breakpoints.md} {
    height: ${({ $size }) => ($size === 'small' ? '22px' : '28px')};
    ${({ theme, $size }) => theme.typography[$size === 'small' ? 'h5' : 'body-small-400'].desktop}
  }

  background: ${({ $color, theme }) => theme.colors[TAG_COLORS[$color].background]};
  color: ${({ $color, theme }) => theme.colors[TAG_COLORS[$color].color]};
`
