import ReactMarkdown, { Components } from 'react-markdown'

import React from 'react'
import { SchemeType } from 'theme'
import styled from 'styled-components'
import { useBlockScheme } from '../Block'

const allowedTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'a', 'ul', 'ol', 'li', 'strong'] as const

type AllowedTags = typeof allowedTags[number]

type Props = {
  className?: string
  scheme?: SchemeType
  children: string
  tags?: AllowedTags[]
  renderer: Components
}

const Markdown = ({ className, scheme, children, renderer, tags }: Props) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme

  return (
    <StyledMarkdown className={className} components={renderer} allowedElements={tags} $scheme={colorScheme}>
      {children}
    </StyledMarkdown>
  )
}

export default Markdown

const StyledMarkdown = styled(ReactMarkdown)<{ $scheme: SchemeType }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${({ theme, $scheme }) => theme.colors[$scheme === 'dark' ? 'white' : 'charcoal']};

  @media ${({ theme }) => theme.breakpoints.lg} {
    gap: 24px;
  }
`
