import { ColorType, SchemeType } from 'theme'
import React, { ReactNode } from 'react'

import styled from 'styled-components'
import { useBlockScheme } from '../Block'

type Props = {
  id?: string
  className?: string
  scheme?: SchemeType
  label: string
  required?: boolean
  error?: string
  value: string
  disabled?: boolean
  children: ReactNode
}

const FieldWrapper = ({ id, className, scheme, label, required, disabled, error, value, children }: Props) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme

  return (
    <Container className={className} $scheme={colorScheme} $disabled={!!disabled}>
      {error && <Message $scheme={colorScheme}>{error}</Message>}

      <Wrapper $scheme={colorScheme} $disabled={disabled} $hasValue={!!value}>
        {children}
      </Wrapper>

      <Label htmlFor={id} $scheme={colorScheme} $hasValue={!!value} $disabled={!!disabled}>
        {label}
        {required && <span>*</span>}
      </Label>
    </Container>
  )
}

export default FieldWrapper

type FieldWrapperSchemeType = {
  [S in SchemeType]: {
    idleColor: ColorType
    activeColor: ColorType
    disabledColor: ColorType
    errorColor: ColorType
    asteriskColor: ColorType
  }
}

const FW_COLOR_SCHEME: FieldWrapperSchemeType = {
  light: {
    idleColor: 'charcoal',
    activeColor: 'blue',
    disabledColor: 'charcoal40',
    errorColor: 'errorLight',
    asteriskColor: 'charcoal60',
  },
  dark: {
    idleColor: 'white',
    activeColor: 'aquamarine',
    disabledColor: 'white40',
    errorColor: 'errorDark',
    asteriskColor: 'white60',
  },
}

const Label = styled.label<{ $scheme: SchemeType; $hasValue: boolean; $disabled: boolean }>`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: default;
  user-select: none;
  color: ${({ $scheme, theme }) => theme.colors[FW_COLOR_SCHEME[$scheme].idleColor]};
  color: ${({ $scheme, $disabled, theme }) => $disabled && theme.colors[FW_COLOR_SCHEME[$scheme].disabledColor]};
  ${({ theme }) => theme.typography.h5.mobile}

  @media ${({ theme }) => theme.breakpoints.md} {
    ${({ theme }) => theme.typography.h5.desktop}
  }

  span {
    color: ${({ $scheme, theme }) => theme.colors[FW_COLOR_SCHEME[$scheme].asteriskColor]};
    color: ${({ $scheme, $hasValue, theme }) => $hasValue && theme.colors[FW_COLOR_SCHEME[$scheme].idleColor]};
    color: ${({ $scheme, $disabled, theme }) => $disabled && theme.colors[FW_COLOR_SCHEME[$scheme].disabledColor]};
  }
`

const Container = styled.div<{ $scheme: SchemeType; $disabled: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: 2px;
  pointer-events: ${({ $disabled }) => $disabled && 'none'};

  :focus-within {
    ${Label} {
      color: ${({ $scheme, theme }) => theme.colors[FW_COLOR_SCHEME[$scheme].activeColor]};

      span {
        color: ${({ $scheme, theme }) => theme.colors[FW_COLOR_SCHEME[$scheme].activeColor]};
      }
    }
  }
`

const Wrapper = styled.div<{
  $scheme: SchemeType
  $disabled?: boolean
  $hasValue: boolean
}>`
  :hover:not(:focus-within) + ${Label} {
    span {
      color: ${({ $scheme, $hasValue, theme }) => $hasValue && theme.colors[FW_COLOR_SCHEME[$scheme].asteriskColor]};
      transition: ${({ theme }) => theme.transitions.color};
    }
  }
`

const Message = styled.div<{ $scheme: SchemeType }>`
  color: ${({ $scheme, theme }) => theme.colors[FW_COLOR_SCHEME[$scheme].errorColor]};

  ${({ theme }) => theme.typography.h5.mobile}

  @media ${({ theme }) => theme.breakpoints.md} {
    ${({ theme }) => theme.typography.h5.desktop}
  }
`
