import { ColorType, SchemeType } from 'theme'
import React, { MouseEvent } from 'react'

import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import styled from 'styled-components'
import { useBlockScheme } from '../Block'

export type SwitchValue = 'monthly' | 'yearly'
export type SwitchSize = 'small' | 'large'

type Props = {
  className?: string
  scheme?: SchemeType
  size?: SwitchSize
  onChange: (e: MouseEvent<HTMLButtonElement>, value: SwitchValue) => void
  value: SwitchValue
}

const Switch = ({ className, scheme, size = 'large', value, onChange }: Props) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme
  const periods: SwitchValue[] = ['monthly', 'yearly']

  return (
    <Container className={className} $scheme={colorScheme} $size={size}>
      {periods.map((period) => (
        <Button
          key={period}
          onClick={(e) => onChange(e, period)}
          value={period}
          $isActive={value === period}
          $scheme={colorScheme}
        >
          <span>Billed</span> {capitalizeFirstLetter(period)}
        </Button>
      ))}
      <Background $activeIndex={value === 'monthly' ? 0 : 1} $scheme={colorScheme} $size={size} />
    </Container>
  )
}

export default Switch

type SwitchSchemeType = {
  [S in SchemeType]: {
    background: ColorType
    buttonBackground: ColorType
    idleColor: ColorType
    activeColor: ColorType
  }
}

const SWITCH_COLOR_SCHEME: SwitchSchemeType = {
  light: {
    background: 'charcoal5',
    buttonBackground: 'teal',
    idleColor: 'teal',
    activeColor: 'white',
  },
  dark: {
    background: 'aquamarine20',
    buttonBackground: 'white',
    idleColor: 'white',
    activeColor: 'teal',
  },
}

const Container = styled.div<{ $scheme: SchemeType; $size: SwitchSize }>`
  width: 296px;
  height: 44px;
  display: flex;
  align-items: center;
  position: relative;
  background: ${({ $scheme, theme }) => theme.colors[SWITCH_COLOR_SCHEME[$scheme].background]};
  border-radius: 10px;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: ${({ $size }) => ($size === 'small' ? '296px' : '336px')};
    height: ${({ $size }) => ($size === 'small' ? '42px' : '54px')};
    border-radius: ${({ $size }) => $size === 'large' && '12px'};
  }
`

const Button = styled.button<{ $isActive: boolean; $scheme: SchemeType }>`
  all: unset;
  flex: 1;
  width: 0;
  height: 100%;
  position: relative;
  z-index: 2;
  text-align: center;
  cursor: pointer;
  color: ${({ $isActive, $scheme, theme }) =>
    theme.colors[SWITCH_COLOR_SCHEME[$scheme][$isActive ? 'activeColor' : 'idleColor']]};
  transition: ${({ theme }) => theme.transitions.switch.color};
  ${({ theme }) => theme.typography.button.mobile};

  @media ${({ theme }) => theme.breakpoints.md} {
    ${({ theme }) => theme.typography.button.desktop};
  }

  span {
    display: none;

    @media ${({ theme }) => theme.breakpoints.sm} {
      display: inline;
    }
  }

  :focus-visible::before {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
    border-radius: 10px;
  }
`

const Background = styled.div<{ $activeIndex: number; $scheme: SchemeType; $size: SwitchSize }>`
  height: 100%;
  width: 50%;
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  background-color: ${({ $scheme, theme }) => theme.colors[SWITCH_COLOR_SCHEME[$scheme].buttonBackground]};
  transform: ${({ $activeIndex }) => `translateX(${$activeIndex * 100}%)`};
  transition: ${({ theme }) => theme.transitions.switch.transform};

  @media ${({ theme }) => theme.breakpoints.md} {
    border-radius: ${({ $size }) => $size === 'large' && '12px'};
  }
`
