import { ColorType, SchemeType } from 'theme'
import React, { MouseEvent } from 'react'

import styled from 'styled-components'
import { useBlockScheme } from '../Block'

type PipsLayout = 'vertical' | 'horizontal'

type Pip = {
  id: string
}

type Props = {
  layout?: PipsLayout
  activeId: string
  items: Pip[]
  scheme?: SchemeType
  onClick: (e: MouseEvent<HTMLButtonElement>, id: string) => void
}

const Pips = ({ layout = 'horizontal', activeId, items, scheme, onClick }: Props) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme

  return (
    <Container $layout={layout}>
      {items.map((item) => (
        <Pip
          id={item.id}
          key={item.id}
          $isActive={activeId === item.id}
          $scheme={colorScheme}
          onClick={(e) => onClick(e, item.id)}
        />
      ))}
    </Container>
  )
}

export default Pips

type PipSchemeType = {
  [S in SchemeType]: {
    background: ColorType
    hoverBackground: ColorType
    activeBackground: ColorType
  }
}

const PIP_COLOR_SCHEME: PipSchemeType = {
  light: {
    background: 'charcoal10',
    hoverBackground: 'charcoal20',
    activeBackground: 'charcoal',
  },
  dark: {
    background: 'white10',
    hoverBackground: 'white20',
    activeBackground: 'white',
  },
}

type StyledContainerProps = {
  $layout: PipsLayout
}

const Container = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: ${({ $layout }) => ($layout === 'vertical' ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  gap: 16px;
`

type StyledBtnProps = {
  $isActive: boolean
  $scheme: SchemeType
}

const Pip = styled.button<StyledBtnProps>`
  all: unset;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  outline: none;

  transition: ${({ theme }) => theme.transitions.background};

  background: ${({ $isActive, $scheme, theme }) =>
    theme.colors[PIP_COLOR_SCHEME[$scheme][$isActive ? 'activeBackground' : 'background']]};

  &:hover {
    background: ${({ $scheme, theme }) => theme.colors[PIP_COLOR_SCHEME[$scheme].hoverBackground]};
  }

  &:active {
    background: ${({ $scheme, theme }) => theme.colors[PIP_COLOR_SCHEME[$scheme].activeBackground]};
  }

  :focus-visible::before {
    content: '';
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;
    border: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
    border-radius: 50%;
  }
`
