import { ColorType, SchemeType } from 'theme'
import React, { MouseEvent } from 'react'

import Icon from '../Icon'
import styled from 'styled-components'
import { useBlockScheme } from '../Block'

type CarouselButtonVariant = 'previous' | 'next'

type Props = {
  id?: string
  className?: string
  variant: CarouselButtonVariant
  disabled?: boolean
  scheme?: SchemeType
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

const CarouselButton = ({ id, className, variant, disabled, scheme, onClick }: Props) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme
  const iconName = variant && (variant === 'next' ? 'caret-right' : 'caret-left')
  const iconColor = CAROUSEL_BUTTON_COLOR_SCHEME[colorScheme][disabled ? 'iconColorDisabled' : 'iconColor']

  if (!iconName) return null

  return (
    <Container>
      <Button
        id={id}
        className={className}
        $scheme={colorScheme}
        disabled={disabled}
        $disabled={!!disabled}
        onClick={onClick}
      >
        <Icon name={iconName} color={iconColor} />
      </Button>
    </Container>
  )
}

export default CarouselButton

type CarouselButtonSchemeType = {
  [S in SchemeType]: {
    iconColor: ColorType
    iconColorDisabled: ColorType
    background: ColorType
    hoverBackground: ColorType
    activeBackground: ColorType
    disabledBackground: ColorType
  }
}

const CAROUSEL_BUTTON_COLOR_SCHEME: CarouselButtonSchemeType = {
  light: {
    iconColor: 'charcoal',
    iconColorDisabled: 'charcoal40',
    background: 'charcoal5',
    hoverBackground: 'charcoal10',
    activeBackground: 'charcoal20',
    disabledBackground: 'charcoal5',
  },
  dark: {
    iconColor: 'white',
    iconColorDisabled: 'white20',
    background: 'white10',
    hoverBackground: 'white20',
    activeBackground: 'white30',
    disabledBackground: 'white10',
  },
}

type StyledBtnProps = {
  $scheme: SchemeType
  $disabled: boolean
}

const Container = styled.div`
  display: flex;
`

const Button = styled.button<StyledBtnProps>`
  all: unset;
  flex-shrink: 0;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  outline: none;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  pointer-events: ${({ $disabled }) => $disabled && 'none'};
  transition: ${({ theme }) => theme.transitions.background};

  background: ${({ $scheme, theme }) => theme.colors[CAROUSEL_BUTTON_COLOR_SCHEME[$scheme].background]};

  :hover {
    background: ${({ $scheme, theme }) => theme.colors[CAROUSEL_BUTTON_COLOR_SCHEME[$scheme].hoverBackground]};
  }

  :active {
    background: ${({ $scheme, theme }) => theme.colors[CAROUSEL_BUTTON_COLOR_SCHEME[$scheme].activeBackground]};
  }

  :disabled {
    background: ${({ $scheme, theme }) => theme.colors[CAROUSEL_BUTTON_COLOR_SCHEME[$scheme].disabledBackground]};
  }

  :focus-visible::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    padding: 30px;
    border: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
    border-radius: 50%;
  }
`
